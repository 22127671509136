// workmimieeee

import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

function Ssearch() {
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  const [searchResults, setSearchResults] = useState(
    location.state?.searchResults || []
  );

  const [selectedMovie, setSelectedMovie] = useState(null);
  const [noResults, setNoResults] = useState(false);

  useEffect(() => {
    if (location.state && location.state.searchQuery) {
      setSearchQuery(location.state.searchQuery);
    }
  }, [location.state]);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!searchQuery) {
      return; // Don't perform search if searchQuery is empty
    }

    fetch(`https://api.tvmaze.com/search/shows?q=${searchQuery}`)
      .then((response) => response.json())
      .then((data) => {
        // console.log("Search Results:", data);
        if (data && data.length > 0) {
          setSearchResults(data);
          // console.log(" miracle Search Results:", data);
          setNoResults(false);
        } else {
          setSearchResults([]);
          setNoResults(true);
        }
      })
      .catch((error) => console.log(error));
  };

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleMovieClick = (show) => {
    setSelectedMovie(show);
    navigate("/series_details", {
      state: {
        selectedMovie: show,
        searchQuery: searchQuery,
        searchResults: searchResults,
      },
    });
  };

  return (
    <div>
      <div className="search-wrapper">
        <form onSubmit={handleSubmit} className="search-form">
          <input
            type="search"
            value={searchQuery}
            onChange={handleInputChange}
            className="search-form-input"
            placeholder="search..."
          />
          <button type="submit" className="search-btn">
            Search
          </button>
        </form>
        {noResults ? (
          <p className="text-center mt-3 text-danger">No results found</p>
        ) : (
          <ul className="search-ul">
            {searchResults.map((result) => (
              <li key={result.show.id} className="card search-li">
                <div className="card-body">
                  {result.show.image && result.show.image.original ? (
                    <img
                      src={result.show.image.original}
                      className="card-img-top"
                      alt="movie pix"
                    />
                  ) : (
                    <div className="no-image-placeholder">
                      No Image Available
                    </div>
                  )}
                  <h5
                    className="card-title movie-link mt-3"
                    onClick={() => handleMovieClick(result.show)}
                  >
                    {result.show.name}
                  </h5>
                  <p className="releasedate">
                    <b>Release Date: </b>
                    {result.show.premiered}
                  </p>
                  <p className="releasedate">
                    <b>Status: </b>
                    {result.show.status}
                  </p>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
}

export default Ssearch;
