// workmimieeeeee
//fix the toggle issue

import React, { useState, useEffect } from "react";
import Homenav from "./Homenav";
import Homefooter from "./Homefooter";
import { useNavigate } from "react-router-dom";
import Ssearch from "./Ssearch";
import Soverview from "./Soverview";

function Series() {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [selectedMovie, setSelectedMovie] = useState(null);
  const [isSearching, setIsSearching] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [cast, setCast] = useState(null);
  const [crew, setCrew] = useState([]);

  useEffect(() => {
    fetch(`https://api.tvmaze.com/shows?page=1`)
      .then((response) => response.json())
      .then((data) => {
        setData(data);
      });
  }, []);

  // casts
  useEffect(() => {
    if (selectedMovie) {
      console.log("Fetching Cast Data for Movie:", selectedMovie);

      fetch(`https://api.tvmaze.com/shows/${selectedMovie.id}/cast`)
        .then((response) => response.json())
        .then((data) => {
          console.log("Cast API Response:", data);
          setCast(data.cast);
        })
        .catch((error) => console.log("Error fetching cast data:", error));
    }
  }, [selectedMovie]);

  useEffect(() => {
    if (selectedMovie) {
      fetch(`https://api.tvmaze.com/shows/${selectedMovie.id}/crew`)
        .then((response) => response.json())
        .then((data) => {
          setCrew(data.crew);
        })
        .catch((error) => console.log(error));
    }
  }, [selectedMovie]);

  const handleMovieClick = (movie) => {
    setSelectedMovie(movie);
    navigate("/series_details", {
      state: {
        selectedMovie: movie,
        cast: cast,
        crew: crew,
        isSearching: isSearching,
      },
    });
  };

  return (
    <>
      <Homenav />

      <Ssearch
        isSearching={isSearching}
        setIsSearching={setIsSearching}
        setSearchResults={setSearchResults}
      />

      <h1 className="text-center mt-4">TV Series</h1>

      {isSearching ? (
        <div className="movies">
          <ul>
            {searchResults.map((result) => (
              <li className="card" key={result.show.id}>
                <div className="card-body">
                  <img
                    src={result.show.image.original}
                    className="card-img-top"
                    alt="movie pix"
                  />

                  <h5
                    className="card-title movie-link mt-3"
                    onClick={() => handleMovieClick(result.show)}
                  >
                    {result.show.name}
                  </h5>

                  <p className="releasedate">
                    <b>Release Date: </b>
                    {result.show.premiered}
                  </p>

                  <p className="releasedate">
                    <b>End Date: </b>
                    {result.show.ended}
                  </p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <div className="movies">
          <ul>
            {data.length > 0 ? (
              data.map((movie) => (
                <li className="card" key={movie.id}>
                  <div className="card-body">
                    <img
                      src={movie.image.original}
                      className="card-img-top"
                      alt="movie pix"
                    />

                    <h5
                      className="card-title movie-link mt-3"
                      onClick={() => handleMovieClick(movie)}
                    >
                      {movie.name}
                    </h5>

                    <p className="releasedate">
                      <b>Release Date: </b>
                      {movie.premiered}
                    </p>

                    <p className="releasedate">
                      <b>End Date: </b>
                      {movie.ended}
                    </p>
                  </div>
                </li>
              ))
            ) : (
              <p>Loading...</p>
            )}
          </ul>
        </div>
      )}

      {selectedMovie && (
        <Soverview selectedMovie={selectedMovie} cast={cast} crew={crew} />
      )}

      {selectedMovie && (
        <div className="overview">
          <h2>{selectedMovie.name}</h2>
          <p>{selectedMovie.summary}</p>
          <p>
            <b>Release Date: </b>
            {selectedMovie.premiered}
          </p>
        </div>
      )}

      <Homefooter />
    </>
  );
}

export default Series;
