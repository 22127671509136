//fix the go back error

import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Homenav from "./Homenav";
import Homefooter from "./Homefooter";

function Soverview() {
  const location = useLocation();

  const navigate = useNavigate();

  const selectedMovie = location.state?.selectedMovie;

  const searchQuery = location.state?.searchQuery;

  const searchResults = location.state?.searchResults || [];

  const cast = location.state?.cast || [];

  const crew = location.state?.crew || [];
  
  const handleGoBack = () => {
    navigate("/series", {
      state: { searchQuery: searchQuery, searchResults: searchResults },
    });
  };

  if (!selectedMovie) {
    return <div>No movie selected</div>;
  }

  // Function to strip HTML tags from a string
  const stripHtmlTags = (html) => {
    const div = document.createElement("div");
    div.innerHTML = html;
    return div.textContent || div.innerText || "";
  };

  return (
    <div>
      <Homenav />
      <div className="series-wrapper">
        <h2 className="text-center mt-5 mb-5">{selectedMovie.name}</h2>

        <button onClick={handleGoBack}>Go Back to Search</button>

        <div className="series">
          <div className="series-img-div">
            <img
              src={selectedMovie.image.original || "Not available"}
              className="series-img"
              alt="movie pix"
            />
          </div>

          <div className="summary-details">
            <h3 className="mb-4">Summary</h3>
            <p> {stripHtmlTags(selectedMovie.summary)}</p>
          </div>

          <div className="series-details">
            <h3 className="mb-4">Show Info</h3>
            <p>
              <b>Release Date: </b>
              {selectedMovie.premiered}
            </p>
            <p>
              <b>End Date: </b>
              {selectedMovie.ended || "Not available"}
            </p>

            <p>
              <b>Status: </b>
              {selectedMovie.status || "Not available"}
            </p>

            <p>
              <b>Episode Runtime: </b>
              {selectedMovie.runtime
                ? selectedMovie.runtime + " minutes"
                : "Not available"}
            </p>

            <p>
              <b>Type: </b>
              {selectedMovie.type || "Not available"}
            </p>

            <p>
              <b>Original Language : </b>
              {selectedMovie.language || "Not available"}
            </p>

            <p>
              <b>Country : </b>{" "}
              {selectedMovie.network.country.name || "Not available"}{" "}
            </p>

            <p>
              <b>Network : </b>
              {selectedMovie.network.name || "Not available"}
            </p>
            <p>
              <b>Movie Genre: </b>{" "}
              {selectedMovie.genres + "  " || "Not available"}
            </p>
          </div>
        </div>

        <div>
          <h2 className=" mt-5 mb-4">Cast</h2>
          {cast.length > 0 ? (
            <ul>
              {cast.map((actor) => (
                <li key={actor.person.id}>
                  <p>
                    <b>Name: </b>
                    {actor.person.name}
                  </p>
                </li>
              ))}
            </ul>
          ) : (
            <p>No cast information available</p>
          )}
        </div>

        <div>
          <h2 className="mt-5 mb-4">Crew</h2>
          {crew.length > 0 ? (
            <ul>
              {crew.map((crewMember) => (
                <li key={crewMember.id}>
                  <p>
                    <b>Name: </b>
                    {crewMember.name}
                  </p>
                </li>
              ))}
            </ul>
          ) : (
            <p>No crew information available</p>
          )}
        </div>
      </div>
      <Homefooter />
    </div>
  );
}

export default Soverview;
